import React from 'react'
import { WebsiteCarbonBadge } from "react-websitecarbon-badge";

export const Sustainability = () => {
  return (
    <section>
      <h3>web sustainability</h3>
      <p>
        Every time you browse the internet or use an online service, you
        indirectly contribute to the production of carbon dioxide (CO2).
        <br /> The energy consumption of websites and online services is
        significant and requires a large amount of electricity to keep servers
        running 24/7. This electricity is often generated by burning fossil
        fuels, which releases CO2 into the atmosphere. In fact, the internet is
        estimated to be responsible for around 3.7% of global CO2 emissions
        <sup>[1]</sup>, equivalent to the carbon footprint of the aviation
        industry.
        <br />
        Factors such as website design, server location, and the type of devices
        used to access the internet all play a role in the amount of CO2
        produced. Therefore, it's crucial to consider the environmental impact
        of websites and online services and take steps to reduce their carbon
        footprint. This can include using renewable energy sources to power
        servers, optimizing website design and code to reduce energy
        consumption, and avoiding unnecessary graphics, images, and videos.
        <br />
        By understanding the environmental impact of websites and online
        services, we can work together to create a more sustainable digital
        future.
        <br />
        For this website, I opted for a provider that uses 100% renewable energy
        <sup>[2]</sup> and a default dark theme to optimize users' screen power
        consumption. I also avoided the use of images and icon fonts to further
        reduce this site's carbon footprint. Additionally, I'm compensating
        for CO2 emissions by planting trees with Treedom<sup>[3]</sup>, a
        sustainable reforestation project.
      </p>
      <h4>data</h4>
      <ul>
        <li>
          <sup>[1]</sup>
          <a
            href="https://www.bbc.com/future/article/20200305-why-your-internet-habits-are-not-as-clean-as-you-think"
            target="_blank"
            rel="noreferrer"
          >
            Why your internet habits are not as clean as you think
          </a>
        </li>
        <li>
          <sup>[2]</sup>
          <a
            href="https://blog.netsons.com/netsons-hosting-provider-green-sostenibile/"
            target="_blank"
            rel="noreferrer"
            
          >
            Netsons l’hosting provider green sostenibile
          </a>
        </li>
        <li>
          <sup>[3]</sup>
          <a
            href="https://www.treedom.net/it/user/enrico-opezzo/trees"
            target="_blank"
            rel="noreferrer"
          >
            treedom
          </a>
        </li>
      </ul>
      <h4>further reading</h4>
      <a
        href="https://abookapart.com/products/sustainable-web-design"
        target="_blank"
        rel="noreferrer"
      >
        sustainable web design
      </a>
      <WebsiteCarbonBadge url="enricoopezzo-com" co2="0.03" percentage="97" />
    </section>
  );
}